<template>
  <div>
    <h3 class="p-2 p-lg-3 my-1 my-lg-3">
      Diseases
    </h3>
    <!--begin::List-->
    <div class="list list-hover">
      <!--begin::Item-->
      <div
        v-for="disease in diseases"
        v-bind:key="disease.name"
        class="list-item hoverable p-2 p-lg-3 mb-2"
      >
        <router-link
          :to="'/disease/' + disease.id"
          class="text-muted text-hover-primary font-weight-bold"
        >
          <div class="d-flex align-items-center">
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-dark-75 font-size-h6 mb-0">
                {{ disease.name }}
              </span>

              {{ disease.description }}
            </div>
            <!--begin::End-->
          </div>
        </router-link>
      </div>
      <!--end::Item-->
      <!--begin::Item-->
      <div
        class="list-item hoverable p-2 p-lg-3 mb-2"
        v-if="user.userTypeId == 4"
      >
        <div class="d-flex align-items-center">
          <v-btn v-b-modal.modal-add-new block color="primary" dark>
            <v-icon left>
              mdi-plus
            </v-icon>
            Add new</v-btn
          >
        </div>
      </div>
      <!--end::Item-->
    </div>
    <!--end::List-->
    <!-- begin::Modal -->
    <b-modal id="modal-add-new" title="Add new disease">
      <b-form @submit="onSubmit">
        <b-form-group id="input-group-1" label="Name:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="modal_data.name"
            required
            placeholder="Enter name"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" variant="danger" @click="onCancel">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="primary" @click="onSubmit">
          Submit
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "HADiseasesMenu",
  data() {
    return {
      diseases: null,
      modal_data: {
        name: "123"
      }
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    ApiService.get("/diseases")
      .then(({ data }) => {
        this.diseases = data.data;
      });
      // .catch(({ error }) => {
      //   console.log(error);
      // });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.modal_data));
    },
    onCancel(evt) {
      evt.preventDefault();
      this.modal_data.name = "";
      this.$bvModal.hide("modal-new-disease");
    }
  }
};
</script>
